import { Message } from 'element-ui';
import request from '../libs/api.request'

export const showMessage = (option) => {
  if (typeof (option) === 'string') {
    option = {message: option}
  }

  if (option.message && typeof (option.message) !== 'string' && option.message.message) {
    option.message = option.message.message;
  }
  Message({
    ...option,
    duration: 2000,
    customClass: 'my-notify',
  })
}

export const handleChapterOperation = (_this,desc, title, url, chapter, message,callback)=> {
  _this.$confirm(desc, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        let loading = _this.$loading({
          lock: true,
          text: '正在处理...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        });
        done();
        request.request({
          url:url,
          method: 'post',
          data: { ...chapter }
        }).then((res) => {
          loading.close();
          showMessage({ message: message, type: 'success' })
          callback()
          // showScrollBar()
        }).catch((e) => {
          loading.close();
          showMessage({ message: e.message, type: 'error' })
        })
      } else {
        done();
        // showScrollBar()
      }
    }
  })
}